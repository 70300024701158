<div
    (click)="changePage()"
    class="animate__animated animate__fadeIn"
    *ngIf="!loading"
    >
    <div
        class="card shadow semi-rounded"
        [ngClass]="{'title-dark': !isDarkmode, 'title-light card-dark': isDarkmode}"
        >
        <div class="card-body">
            <h5 class="card-title">{{ "title_cardviews.card_last_ten_movements" | translate }}</h5>
            <div class="container">
                <div
                    class="container"
                    *ngIf="noData">
                    <div class="row">
                        <div class="col">
                            <img
                                class="img-fluid"
                                src="assets/images/empty_cards/card-last-ten-days.png"
                                >
                        </div>
                    </div>
                </div>
                <ng-container *ngFor="let movement of movements">
                    <div class="row mb-1">
                        <div class="col-1 me-3 clear-padding">
                            <img
                                src="assets/images/icon-cat/{{movement.iconName}}.svg"
                                width="45"
                                style="padding: 4px; filter: {{movement.filter}}"
                                >
                          <!--filter: {{getFilter(movement.colorHex)}}-->
                        </div>
                        <div class="col-5 clear-padding">
                            <div class="row">
                                <div
                                    class="col-12 clear-padding"
                                    style="margin-bottom: -4px;"
                                    [ngClass]="{'pt-2': !movement.detail}">
                                    {{movement.category?.name ?? ('words.transfer' | translate )}}
                                    <ng-container *ngIf="movement.subcategory">
                                        - {{movement.subcategory!.name}}
                                    </ng-container>
                                </div>
                                <div
                                    class="col-12 clear-padding font-secondary"
                                    style="font-size: 12px;"
                                    *ngIf="movement.detail"
                                    >{{ movement.detail }}
                                </div>
                            </div>
                        </div>
                        <div class="col-5 clear-padding">
                            <div
                                class="text-end text-right"
                                [ngClass]="{'pt-2': !movement.detail}">
                                <p *ngIf="movement.sign == '-'">
                                    {{ movement.amount | currencyFormat }}
                                    <i class="fa fa-minus text-end text-right " aria-hidden="true"></i>
                                </p>
                                <p *ngIf="movement.sign == '+'">
                                    {{ movement.amount | currencyFormat }}
                                    <i class="fa fa-plus text-end text-right " aria-hidden="true"></i>
                                </p>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
