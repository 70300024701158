import { Injectable } from '@angular/core';
import { PreferencesModel } from 'src/app/models/preferences.model';
import { CardControl } from '../interfaces/CardsControl.interface';
import {Api3Service} from '../../../services/api-3.service';

@Injectable({
  providedIn: 'root'
})
export class CardsService {

  private _preferences: PreferencesModel = PreferencesModel.shared;
  private userId = this.api3.userData?.user_id;

  constructor(
    private api3: Api3Service
  ) { }

  public updateCards(cards: CardControl[]) {
    let params = {
      data: this.getCardsParams(cards)
    }
    return new Promise((resolve, _) => {
      this.api3.update(params, 'table_cards').subscribe(response => {
        resolve(true);
      });
    });
  }

  public getCardsParams(cards: CardControl[]) {
    let params : any = [];
    cards.forEach(card => {
      params.push({
        pk_user_card : card.id,
        shown : card.hidden ? 0 : 1,
        order_card : card.order,
        fk_preference: this._preferences.pkPreference,
        fk_user: this.userId
      });
    });
    return params;
  }

}
