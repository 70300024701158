import {Response, ResponseApi3} from 'src/app/interfaces/responses-general.interface';
import { Injectable } from '@angular/core';
import { SyncMovements } from 'src/app/interfaces/sync.interface';
import { MovementModel } from 'src/app/models/general/movement.model';
import { AccountModel } from 'src/app/models/general/account.model';
import { CategoryModel } from 'src/app/models/general/category.model';
import { SubcategoryModel } from 'src/app/models/general/subcategory.model';
import { Account } from 'src/app/interfaces/general/account.interface';
import { Category } from 'src/app/interfaces/general/category.interface';
import { Subcategory } from 'src/app/interfaces/general/subcategory.interface';
import {Movement, MovementData} from 'src/app/interfaces/general/movement.interface';
import { DayMovementModel } from 'src/app/models/general/day.movement.model';
import moment from 'moment';
import {
  BalanceData,
  DataMovements, DateReportDate,
  ImagesAndId,
  ImagesUpload,
  MultipleChangeValues,
  ReportDateGroup
} from '../../../interfaces/general/utils.interface';
import {Picture} from '../../../interfaces/general/picture.interface';
import {PictureModel} from '../../../models/general/picture.model';
import {DateGroupBy} from '../../../interfaces/enums/utils.enums';
import {SharedAccount, SharedBetweenUsers} from '../../../interfaces/general/shared.data.interface';
import {SharedAccountModel} from '../../../models/general/shared.account.model';
import StartOf = moment.unitOfTime.StartOf;
import {Api3Service} from '../../../services/api-3.service';

@Injectable({
  providedIn: 'root'
})
export class MovementsService {

  private modelMovements: MovementModel[] = [];
  private modelAccounts: AccountModel[] = [];
  private modelCategories: CategoryModel[] = [];
  private modelSubcategories: SubcategoryModel[] = [];
  private dayMovementModel: DayMovementModel[] = [];
  private sharedAccounts: SharedAccountModel[] = [];

  private subscriptionId = this.api3.userData?.subscription_id;
  private userId = this.api3.userData?.user_id;
  private tableName = 'table_movements';

  public showAnimationLoad = false;

  constructor(
    private api3: Api3Service
  ) { }

  public getMovements(): MovementModel[] {
    return [...this.modelMovements];
  }

  public getAccounts(): AccountModel[] {
    return [...this.modelAccounts];
  }

  public getCategories(): CategoryModel[] {
    return [...this.modelCategories];
  }

  public getSubcategories(): SubcategoryModel[] {
    return [...this.modelSubcategories];
  }

  public getDayMovementModel(): DayMovementModel[] {
    return [...this.dayMovementModel];
  }

  //MARK: PUBLIC METHODS ---------------------------------------------------------------------
  public getSync(requiredModel: boolean = true) {
    return new Promise((resolve, reject) => {
      if (this.modelMovements.length > 0) {
        setTimeout(() => {
          resolve(true);
          return;
        }, 250)
      }
      this.api3.sync('movements').subscribe((response: ResponseApi3<SyncMovements>) => {
        if (response.status === 1) {
          const data = response.data;
          this.setAccountModel(data.table_accounts);
          this.setSubcategoryModel(data.table_subcategories);
          this.setCategoryModel(data.table_categories);
          this.setMovementModel(data.table_movements);
          this.setSharedData(data.user_subscription_shared);
          this.setModelPicture(data.table_pictures);
          resolve(true);
        } else {
          reject(response.message);
        }
      });
    });
  }

  public createMovement(values: Movement, fkToAccount: number | null, images: ImagesUpload[]) {
    values.fk_user = this.userId!;
    const table = fkToAccount ? 'transfer' : this.tableName;
    const names = images.map(row => row.name);
    const params = {
      data: {
        ...values,
        fk_subscription: this.subscriptionId,
        fk_to_account: fkToAccount
      },
      pictures: names
    }
    return new Promise((resolve, reject) => {
      this.api3.insert(params, table).subscribe((response: ResponseApi3<any>) => {
        if (response.status === 1) {
          const data = response.data;
          let pkMovement = 0;
          if (!!fkToAccount) {
            for (const row of data as Movement[]) {
              const model = new MovementModel(row);
              pkMovement = model.pkMovement;
              this.setRelations(model);
              this.modelMovements.push(model);
            }
          } else {
            const movement = data as MovementData;
            const model = new MovementModel(movement.table_movements[0]);
            pkMovement = model.pkMovement;
            this.setRelations(model);
            this.modelMovements.push(model);
          }
          this.setModelPicture(data.table_pictures);
          this.uploadImages(images, pkMovement);
          resolve(true);
        } else {
          reject(response.message);
        }
      });
    });
  }

  public updateMovement(movement: Movement, fkToAccount: number | null, pkTransfer: number | null, images: ImagesUpload[]) {
    movement.fk_user = this.userId!;
    const table = fkToAccount ? 'transfer' : this.tableName;
    const names = images.map(row => row.name);
    const params = {
      data: {
        ...movement,
        fk_to_account: fkToAccount,
        pk_transfer: pkTransfer,
        fk_subscription: this.subscriptionId
      },
      pictures: names,
    }
    return new Promise((resolve, reject) => {
      this.api3.update(params, table).subscribe((response: ResponseApi3<any>) => {
        if (response.status === 1) {
          let data = response.data;
          let movements: Movement[] = [];
          if (!!fkToAccount) {
            movements = data as Movement[];
          } else {
            data = data as MovementData;
            movements = data.table_movements;
          }
          for (const movement of movements) {
            const model = this.modelMovements.find(row => row.pkMovement === movement.pk_movement);
            const category = this.modelCategories.find(row => row.pkCategory === movement.fk_category);
            const account = this.modelAccounts.find(row => row.pkAccount === movement.fk_account);
            const subcategory = this.modelSubcategories.find(row => row.pkSubcategory === movement.fk_subcategory);
            if (model) {
              model.updateValues(movement, account, category, subcategory);
            }
          }
          this.setModelPicture(data.table_pictures);
          this.uploadImages(images, movement.pk_movement);
          resolve(true);
        } else {
          reject(response.message);
        }
      });
    });
  }

  public deleteMovement(pkMovements: number[]) {
    const params = {
      table: this.tableName,
      column: 'pk_movement',
      values: pkMovements,
      fk_user: this.userId!,
      fk_subscription: this.subscriptionId!
    }
    return new Promise((resolve, reject) => {
      this.api3.delete(params, 'delete_array').subscribe((response: ResponseApi3<any>) => {
        if (response.status === 1) {
          this.modelMovements = this.modelMovements.filter(row => !pkMovements.includes(row.pkMovement));
          resolve(true);
        } else {
          reject(response.message);
        }
      });
    });
  }

  //MARK: MANAGER PICTURES
  public uploadImages(images: ImagesUpload[], fkMovement: number) {
    for (const image of images) {
      this.uploadFileImage(image);
    }
  }

  public deleteImages(images: ImagesAndId[], model: MovementModel) {
    const names = images.map(row => row.name);
    const params: object = {
      get: 'list_delete',
      fk_subscription: this.api3.userData?.subscription_id,
      email: this.api3.userData?.email,
      list_names: names
    }
    /*return new Promise(() => {
      this.api.awsImage(params).subscribe((response: Response<any>) => {
        if(response.status == 1) {
          model.pictures = model.pictures.filter(row => !names.includes(row.name));
          model.hasPictures = (model.pictures.length > 0);
          this.removePictureRecords(images.map(row => row.pkPicture));
        }
      });
    });*/
  }

  public removePictureRecords(pkPictures: number[]) {
    const params = {
      get: 'deleteIn',
      table: 'table_pictures',
      idColumnName: 'pk_picture',
      args: pkPictures,
      pk_subscription: this.api3.userData?.subscription_id
    }
    /*this.api.delete(params).subscribe(_ => {
    });*/
  }

  public multipleUpdateMovement(values: MultipleChangeValues) {
    const params: object = {
      data: {
        pk_movements: values.idMovements,
        fk_account: values.fkAccount,
        fk_category: values.fkCategory,
        fk_subcategory: values.fkSubcategory
      }
    }
    return new Promise((resolve, reject) => {
     this.api3.update(params, 'update_accounts_or_categories').subscribe((response: Response<Movement[]>) => {
        if(response.status === 1) {
          this.updateMovementModel(response.data);
          resolve(true);
        } else {
          reject();
        }
      });
    });
  }


  public generateModelInRange(date: moment.Moment, group: StartOf): Promise<void> {
    return new Promise(resolve => {
      const movements = this.modelMovements.filter(row => date.isSame(row.date, group));
      const chunkSize = 25;
      let index = 0;
      this.dayMovementModel = [];
      const processChunk = () => {
        const chunk = movements.slice(index, index + chunkSize);
        if (index < movements.length) {
          const movementDates = this.getObjectMovementsByDate('day', chunk);
          const model = this.getArrayDayMovementModel(movementDates);
          this.dayMovementModel.push(...model);
          index += chunkSize;
          requestAnimationFrame(processChunk);
        } else {
          this.orderDayMovementModel();
          resolve();
        }
      }
      requestAnimationFrame(processChunk);
    })
  }

  //MARK: GENERATE MOVEMENTS MODEL -------------------------------------------------------------------
  public generateModelView(date: moment.Moment, accounts: number[], status: number,  startOf: StartOf, categories?: number[], type?: string): Promise<DataMovements> {
    return new Promise(resolve => {
      this.dayMovementModel = [];
      const movements = this.filterMovements(date, accounts, status, startOf, categories, type);
      this.generateDayMovementModel(movements).then(balance => {
        this.orderDayMovementModel();
        resolve({
          model: this.dayMovementModel,
          totalBalance: (balance.incomes - balance.expenses)
        });
      });
    })
  }

  public generateModelReports(date: moment.Moment, accounts: number[], groupBy: ReportDateGroup, isFortnightly: boolean, typeMovement?: string, showTransfers?: boolean): Promise<DateReportDate> {
    this.dayMovementModel = [];
    return new Promise(resolve => {
      const movements = (groupBy.group !== 'year') ? this.filterMovements(date, accounts, 2, groupBy.filter, undefined, typeMovement, showTransfers) : this.modelMovements;
      this.generateDayMovementModel(movements, groupBy.group, groupBy.format, isFortnightly).then(balance => {
        resolve({
          model: this.dayMovementModel,
          incomes: balance.incomes,
          expenses: balance.expenses
        });
      });
    })

  }

  private generateDayMovementModel(movements: MovementModel[], findDate?: StartOf, format?: string, isFortnightly?: boolean): Promise<BalanceData> {
    return new Promise(resolve => {
      let incomes = 0;
      let expenses = 0;
      for (const movement of movements) {
        const dayModel = this.findModelDay(movement.date, findDate, isFortnightly)
        if (movement.sign === '+') {
          incomes = movement.amount + incomes;
        } else if (movement.sign === '-') {
          expenses = movement.amount + expenses;
        }
        if (dayModel) {
          dayModel.movements.push(movement);
        } else {
          const model = new DayMovementModel(moment(movement.date, 'YYYY-MM-DD', false), [movement], format)
          this.dayMovementModel.push(model);
        }
      }
      resolve({ incomes, expenses });
    })
  }

  private findModelDay(rowDate: string,findDate?: StartOf, isFortnightly?: boolean) {
    const movementDate = moment(rowDate);
    if (isFortnightly) {
      const start = movementDate.clone().startOf('month').date(movementDate.date() >= 16 ? 16 : 1);
      const end = (movementDate.date() >= 16) ? movementDate.clone().endOf('month') : movementDate.clone().startOf('month').date(16);
      return this.dayMovementModel.find(row => {
        return row.date.isBetween(start, end, null, '[]');
      });
    } else {
      if (findDate) {
        return this.dayMovementModel.find(row => row.date.isSame(movementDate, findDate));
      }
      return this.dayMovementModel.find(row => row.date.date() === movementDate.date());
    }

  }

  private filterMovements(date: moment.Moment, accounts: number[], status: number,  startOf: StartOf,  categories?: number[], type?: string, showTransfers?: boolean) {
    let movements = this.modelMovements.filter(row => {
      return (date.isSame(row.date, startOf)) && accounts.includes(row.fkAccount);
    });
    if (categories) {
      movements = movements.filter(row => categories.includes(row.fkCategory ?? 0));
    }
    if (status !== 2) {
      movements = movements.filter(row => row.status === status);
    }
    if (showTransfers != undefined && !showTransfers) {
      movements = movements.filter(row => row.transfer === 0);
    }
    if (type === '<>') {
      movements = movements.filter(row => row.transfer === 1);
    } else if (type === '-' || type === '+') {
      movements = movements.filter(row => row.sign === type);
    }
    return movements;
  }

  public setDayMovementModel(groupBy: DateGroupBy = 'day', movements: MovementModel[] | null = null, isFortnight: boolean = false, currentDate: moment.Moment | null = null) {
    this.dayMovementModel = [];
    let currentMovements = (movements) ? movements : this.modelMovements;
    if (currentDate) {
      currentMovements = currentMovements.filter(row => currentDate.isSame(row.date, 'month'));
    }
    const movementDates = this.getObjectMovementsByDate(groupBy, currentMovements, isFortnight);
    this.dayMovementModel = this.getArrayDayMovementModel(movementDates);
    this.orderDayMovementModel();
  }

  public getArrayDayMovementModel(movementDates: any): DayMovementModel[] {
    return Object.keys(movementDates).map(dateKey => {
      const date = moment(dateKey, 'DD-MMMM-YYYY', true);
      const movements = movementDates[dateKey];
      return new DayMovementModel(date, movements);
    });
  }

  public getObjectMovementsByDate(groupBy: DateGroupBy, currentMovements: MovementModel[], isFortnight: boolean = false): { [dateKey: string]: MovementModel[] } {
    return currentMovements.reduce((acc, movement) => {
      let dateKey: string = '';
      if(isFortnight) {
        const movementDate = moment(movement.date);
        const dayOfMonth = movementDate.date();
        const dateFortnight = movementDate.startOf('month').date((dayOfMonth <= 15 ? 1 : 16));
        dateKey = dateFortnight.format('DD-MMMM-YYYY');
      } else {
        const movementDate = moment(movement.date).startOf(groupBy);
        dateKey = movementDate.format('DD-MMMM-YYYY');
      }
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(movement);
      return acc;
    }, {} as { [dateKey: string]: MovementModel[] });
  }

  //MARK: PRIVATE METHODS --------------------------------------------------------------------
  private uploadFileImage(image: ImagesUpload) {
    const formData = new FormData();
    formData.append('request', 'aws_file');
    formData.append('server', this.api3.dbServer);
    formData.append('option', 'upload');
    formData.append('name', image.name);
    formData.append('version', 'WEB');
    formData.append('profile', '0');
    formData.append('file', image.file);
    formData.append('email', this.api3.userData!.email);
    this.api3.file(formData).subscribe();
  }

  private setSharedData(data: SharedBetweenUsers) {
    if (!data) { return }
    this.setSharedAccount(data.shared_accounts);
    this.setAccountModel(data.table_accounts, false, true);
    this.setCategoryModel(data.table_categories, false, true);
    this.setSubcategoryModel(data.table_subcategories, false);
    this.setMovementModel(data.table_movements, false, true);
  }

  private setSharedAccount(shared: SharedAccount[]) {
    this.sharedAccounts = [];
    for (const sharedAccount of shared) {
      this.sharedAccounts.push(new SharedAccountModel(sharedAccount));
    }
  }

  private setMovementModel(movements: Movement[], reset: boolean = true, isShared: boolean = false) {
    if(reset) {
      this.modelMovements = [];
    }
    for (const movement of movements) {
      const model = this.getMovementModel(movement);
      if (model) {
        model.isShared = isShared;
        this.setMovementTransfer(model, movements);
        this.modelMovements.push(model);
      }
    }
    this.showAnimationLoad = (this.modelMovements.length >= 3000);
  }

  private getMovementModel(movement: Movement): MovementModel | null {
    const account = this.modelAccounts.find(row => row.pkAccount == movement.fk_account && row.deleted == 0);
    const category = this.modelCategories.find(row => row.pkCategory == movement.fk_category && row.deleted == 0);
    const subcategory = this.modelSubcategories.find(row => row.pkSubcategory == movement.fk_subcategory && row.deleted == 0);
    if (!!movement.fk_subcategory && !subcategory) { return null }
    if (!!movement.fk_category && !category) { return null }
    if (!!movement.fk_subcategory && !subcategory) { return null }
    if (account) {
      return new MovementModel(movement, account, category, subcategory);
    }
    return null;
  }

  private pushMovementModel(movements: Movement[]) {
    for (const movement of movements) {
      const model = new MovementModel(movement);
      this.setRelations(model);
      this.setMovementTransfer(model, movements);
      this.modelMovements.push(model);
      const dayModel = this.dayMovementModel.find(row => row.date.isSame(model.date, 'day'));
      if(dayModel) {
        dayModel.movements.unshift(model);
      } else {
        this.dayMovementModel.unshift(
          new DayMovementModel(moment(model.date), [model])
        );
      }
    }
  }

  private setMovementTransfer(model: MovementModel, movements: Movement[]) {
    if(model.transfer == 1) {
      const movementTransfer = movements.find(row => {
        return (row.pk_movement != model.pkMovement && row.transfer_code == model.transferCode);
      });
      model.fkToAccount = movementTransfer?.fk_account ?? null;
      model.pkTransfer = movementTransfer?.pk_movement ?? null;
    }
  }

  private updateMovementModel(movements: Movement[]) {
    for (const movement of movements) {
      const day = this.dayMovementModel.find(row => row.movements.some(row => row.pkMovement === movement.pk_movement));
      if (day) {
        const movementModel = day.movements.find(row => row.pkMovement === movement.pk_movement);
        if (movementModel) {
          const account = this.modelAccounts.find(row => row.pkAccount === movement.fk_account);
          const category = this.modelCategories.find(row => row.pkCategory === movement.fk_category);
          const subcategory = this.modelSubcategories.find(row => row.pkSubcategory === movement.fk_subcategory);
          movementModel.updateValues(movement, account, category, subcategory);
        }
      }
    }
    this.orderDayMovementModel();
  }

  private orderDayMovementModel() {
    this.dayMovementModel = this.dayMovementModel.sort((a, b) => {
      if (a.date.isAfter(b.date)) {
        return -1;
      } else if (a.date.isBefore(b.date)) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  private setRelations(model: MovementModel) {
    const category = this.modelCategories.find(({ pkCategory }) => pkCategory == model.fkCategory);
    const account = this.modelAccounts.find(({ pkAccount }) => pkAccount == model.fkAccount);
    const subcategory = this.modelSubcategories.find(({ pkSubcategory }) => pkSubcategory == model.fkSubcategory);
    if (category) {
      model.category = category;
    }
    if (account) {
      model.account = account;
    }
    if (subcategory) {
      model.subcategory = subcategory;
    }
    model.setColorAndIcon();
  }

  private setModelPicture(pictures: Picture[]) {
    if (!pictures || !Array.isArray(pictures)) { return }
    for (const picture of pictures) {
      const movement = this.modelMovements.find(row => row.pkMovement === picture.fk_movement);
      if(movement) {
        movement.pictures.push(new PictureModel(picture));
        movement.hasPictures = true;
      }
    }
  }

  private setAccountModel(accounts: Account[], reset: boolean = true, isShared: boolean = false) {
    if(reset) {
      this.modelAccounts = [];
    }
    for (const account of accounts) {
      const model = new AccountModel(account);
      model.isShared = isShared;
      if(isShared) {
        model.shared = this.sharedAccounts.find(row => row.fkAccount == model.pkAccount) ?? null;
      }
      this.modelAccounts.push(model);
    }
    this.modelAccounts = this.modelAccounts.sort((a, b) => a.accountName.localeCompare(b.accountName));
  }

  private setCategoryModel(categories: Category[], reset: boolean = true, isShared: boolean = false) {
    if(reset) {
      this.modelCategories = [];
    }
    for (const category of categories) {
      const model = new CategoryModel(category);
      model.subcategories = this.modelSubcategories.filter(({fkCategory}) => fkCategory == model.pkCategory);
      model.isShared = isShared;
      this.modelCategories.push(model);
    }
    this.modelCategories = this.modelCategories.sort((a, b) => a.name.localeCompare(b.name));
  }

  private setSubcategoryModel(subcategories: Subcategory[], reset: boolean = true) {
    if (reset) {
      this.modelSubcategories = [];
    }
    this.modelSubcategories = [];
    for (const subcategory of subcategories) {
      this.modelSubcategories.push(new SubcategoryModel(subcategory));
    }
    this.modelSubcategories = this.modelSubcategories.sort((a, b) => a.name.localeCompare(b.name));
  }
}
