import {Injectable} from '@angular/core';
import {PreferencesSessionStorage} from 'src/app/utils/local-storage-manager/session-storage-preferences.utils';
import {Api3Service} from '../../../services/api-3.service';
import {LoginData, ResponseLogin} from '../../../interfaces/general/login.interface';
import moment from 'moment';
import {ResponseApi3} from '../../../interfaces/responses-general.interface';
import {User} from '../../../interfaces/general/user.interface';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private preferencesManager = new PreferencesSessionStorage();
  private appVersion = '4.103';
  _pkUserPassword: string = "";
  _tokenPassword: string = "";
  _code: number = 0;
  _email: string = "";

  public get pkUserPassword(): string {
    return this._pkUserPassword;
  }

  public get tokenPassword(): string {
    return this._tokenPassword;
  }

  public get getCode(): number {
    return this._code;
  }

  public get getEmail(): string {
    return this._email;
  }

  constructor(
    private api3: Api3Service,
  ) {
  }

  //MARK: PUBLIC METHODS -----------------------------------------------------------------------------
  public login(data: any) {
    const params = {
      ...data,
      device: this.getDevice()
    }
    return new Promise((resolve, reject) => {
      this.api3.withoutToken(params, 'user', 'login').subscribe((response: ResponseLogin) => {
        if (response.status === 1 && response.found === 1) {
          const active = this.saveUserData(response.data);
          resolve(active);
        } else {
          sessionStorage.setItem('logged', '0');
          if (response.message.includes('USER_DISABLED')) {
            reject(false);
          } else {
            reject(response.message);
          }
        }
      });
    });
  }

  public recoverPassword(values: any, messages: string[]) {
    const code = this.generateCode();
    const params = {
      code: code,
      email: values.email,
      search_email: 1,
      app_name: messages[0],
      title: messages[1],
      body_message: messages[2],
    };
    return new Promise((resolve, reject) => {
      this.api3.withoutToken(params, 'email', 'send_code').subscribe((response: ResponseApi3<any>) => {
        if (response.status === 1) {
          this._code = code;
          this._email = values.email;
          resolve(true);
        } else {
          reject(response.message);
        }
      });
    });
  }

  public async changePassword(password: string, email: string) {
    const user = await this.getUserResetPassword(email);
    const params = {
      email: user.email,
      password: password,
      pk_user: user.pk_user
    }
    return new Promise((resolve, reject) => {
      this.api3.withoutToken(params, 'user', 'password_update').subscribe((response: ResponseApi3<User>) => {
        if (response.status === 1) {
          resolve(true);
        } else {
          reject(response.message);
        }
      });
    });
  }

  //MARK: PRIVATE METHODS ----------------------------------------------------------------------------
  private getUserResetPassword(email: string): Promise<User> {
    const params = {
      service: 1,
      email: email
    }
    return new Promise((resolve, reject) => {
      this.api3.withoutToken(params, 'user', 'search').subscribe((response: ResponseApi3<User[]>) => {
        if (response.status === 1) {
          resolve(response.data[0]);
        } else {
          reject();
        }
      });
    });
  }

  private generateCode() {
    let random = Math.floor(Math.random() * (100000-999979) + 20);
    return random < 0 ? random * -1 : random;
  }

  private getDevice() {
    const userAgent = navigator.userAgent;
    let deviceInfo = {
      device: 'Unknown',
      model: 'Unknown model',
      phone_version: 'Unknown',
      app_version: this.appVersion
    }
    if (userAgent.includes("Firefox")) {
      deviceInfo.device = "Firefox";
    } else if (userAgent.includes("Chrome") && !userAgent.includes("Edg")) {
      deviceInfo.device = "Chrome";
    } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
      deviceInfo.device = "Safari";
    } else if (userAgent.includes("Edg")) {
      deviceInfo.device = "Edge";
    } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
      deviceInfo.device = "Opera";
    }

    if (userAgent.includes("Windows NT 10.0")) {
      deviceInfo.model = "Windows";
      deviceInfo.phone_version = "10";
    } else if (userAgent.includes("Windows NT 6.3")) {
      deviceInfo.model = "Windows";
      deviceInfo.phone_version = "8.1";
    } else if (userAgent.includes("Windows NT 6.1")) {
      deviceInfo.model = "Windows";
      deviceInfo.phone_version = "7";
    } else if (userAgent.includes("Mac OS X")) {
      deviceInfo.model = "MacOS";
      const versionMatch = userAgent.match(/Mac OS X (\d+[_\.\d]+)/);
      deviceInfo.phone_version = versionMatch ? versionMatch[1].replace(/_/g, '.') : "Unknown";
    } else if (userAgent.includes("Android")) {
      deviceInfo.model = "Android";
      const versionMatch = userAgent.match(/Android\s([0-9.]+)/);
      deviceInfo.phone_version = versionMatch ? versionMatch[1] : "Unknown";
    } else if (userAgent.match(/iPhone|iPad|iPod/)) {
      deviceInfo.model = "iOS";
      const versionMatch = userAgent.match(/OS (\d+_\d+)/);
      deviceInfo.phone_version = versionMatch ? versionMatch[1].replace('_', '.') : "Unknown";
    } else if (userAgent.includes("Linux")) {
      deviceInfo.model = "Linux";
    }

    return deviceInfo;
  }

  private async saveLogin(data: any) {
    const params = {
      data: { fk_user: data.user_id }
    }
    return this.api3.insert(params, 'history_login_web');
  }

  private saveUserData(data: LoginData) {
    const preferences = data.user_preferences[0];
    const user = data.user_accounts[0];
    const subscription = data.user_subscriptions[0];
    const authData = {
      user_id: user.pk_user,
      subscription_id: subscription.pk_subscription,
      email: user.email,
      token: data.token
    }
    sessionStorage.setItem('user_data', JSON.stringify(authData));
    sessionStorage.setItem('logged', '1');
    sessionStorage.setItem('type_subscription', subscription.type.toString());
    this.preferencesManager.savePreferences(preferences);
    const darkMode = preferences.theme === 1 ? 'true' : 'false';
    localStorage.setItem('darkmode', darkMode);
    this.saveLogin(authData).then();
    return moment().isSameOrBefore(moment(subscription.date_finish));
  }
}
