import {Component, OnInit} from '@angular/core';
import {PreferencesModel} from 'src/app/models/preferences.model';
import {PeriodType} from '../../../common-components/selector-period/interfaces/period.interface';
import {LocalStorageManager} from '../../../../utils/local-storage-manager/local-storage-manager.utils';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {PreferencesSessionStorage} from 'src/app/utils/local-storage-manager/session-storage-preferences.utils';
import {FilterService} from '../../../../services/filter.service';
import {ChartPie} from '../../../../interfaces/general/reports.interfaces';
import {MovementModel} from '../../../../models/general/movement.model';
import moment from 'moment';
import {AccountModel} from '../../../../models/general/account.model';

@Component({
  selector: 'app-monthly-balance-previous-balance',
  templateUrl: './monthly-balance-previous-balance.component.html',
  styleUrls: ['./monthly-balance-previous-balance.component.css']
})
export class MonthlyBalancePreviousBalanceComponent implements OnInit {
  showTransfers: boolean = new PreferencesSessionStorage().getHome();
  isDarkmode = LocalStorageManager.isDarkmode;

  preferences = PreferencesModel.shared;
  totalIncomes: number = 0;
  totalExpenses: number = 0;
  totalBalance: number = 0;
  previousBalance: number = 0;
  titleReport: string = '';
  chartData: ChartPie[] = [];
  customColors = [
    { name: 'Incomes', value: '#0EB59499' },
    { name: 'Expenses', value: '#D1359499' },
    { name: 'Total', value: '#42424299' }
  ];

  constructor(
    private translate: TranslateService,
    private route: Router,
    private filters: FilterService
  ) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnInit(): void {
    this.getBalances(this.filters.movementsPeriod);
  }

  //MARK: PUBLIC METHODS -----------------------------------------------------------------------------
  public changePage() {
    this.route.navigate(['Reports-by-date/reports']);
  }

  //MARK: PRIVATE METHODS ----------------------------------------------------------------------------
  private getBalances(movements: MovementModel[]) {
    setTimeout(() => {
      this.resetTotals();
      this.titleReport = this.getTitle();
      this.getCurrentBalance(movements);
      this.getPreviousBalance();
      this.totalBalance = (this.previousBalance + this.totalIncomes) - this.totalExpenses;
      this.generateChartData();
    }, 100);
  }

  private getCurrentBalance(movements: MovementModel[]) {
    this.totalExpenses = this.getSumMovements('-', movements);
    this.totalIncomes = this.getSumMovements('+', movements);
  }

  private getPreviousBalance() {
    const initBalanceAccount = this.getInitBalanceAccount();
    const movements = this.getMovementsPrevious();
    const incomes = this.getSumMovements('+', movements);
    const expenses = this.getSumMovements('-', movements);
    this.previousBalance = (initBalanceAccount + incomes) - expenses;
  }

  private getInitBalanceAccount(): number {
    return this.filters.accounts
      .filter(row => row.initialBalance !== null)
      .reduce((sum, row) => sum + row.initialBalance, 0);
  }

  private getMovementsPrevious(): MovementModel[] {
    const date = this.getDate();
    const accountsSet = new Set<AccountModel>(this.filters.accounts);
    return this.filters.movementsAll.filter(movement => {
      const inAccount = accountsSet.has(movement.account!);
      if (!inAccount) { return false }
      return moment(movement.date).isSameOrBefore(date, 'day');
    });
  }

  private getDate(): moment.Moment {
    const date = this.filters.currentDate.clone();
    switch (this.filters.period) {
      case PeriodType.daily:
        return date;
      case PeriodType.weekly:
        return date.startOf('week').subtract(1, 'day');
      case PeriodType.fortnightly:
        if (date.date() <= 15) {
          return date.startOf('month').subtract(1, 'day');
        } else {
          return date.startOf('month').add(14, 'day');
        }
      case PeriodType.monthly:
        return date.startOf('month').subtract(1, 'day');
      default:
        return date.startOf('year').subtract(1, 'day');
    }
  }

  private resetTotals() {
    this.totalBalance = 0;
    this.totalIncomes = 0;
    this.totalExpenses = 0;
    this.previousBalance = 0;
    this.chartData = [];
  }

  private getSumMovements(sign: string, movements: MovementModel[]): number {
    return movements.filter(row => row.sign === sign).reduce((sum, row) => sum + row.amount, 0);
  }

  private getTitle(): string {
    switch(this.filters.period) {
      case PeriodType.daily: return "text_cardviews.card_balance_previous_by_day";
      case PeriodType.weekly: return "text_cardviews.card_balance_previous_weekly";
      case PeriodType.fortnightly: return "text_cardviews.card_balance_previous_biweekly";
      case PeriodType.monthly: return "text_cardviews.card_balance_previous_monthly";
      case PeriodType.annually: return "text_cardviews.card_balance_previous_yearly";
      case PeriodType.byDates: return "text_cardviews.card_balance_previous_date_range";
    }
  }

  private generateChartData() {
    this.chartData = [
      {name: 'Incomes', value: this.totalIncomes},
      {name: 'Expenses', value: this.totalExpenses},
      {name: 'Total', value: this.totalBalance},
    ]
  }
}
