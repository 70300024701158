export enum AccountType {
  incomes = "1",
  expenses = "0",
}

export enum TypeStatus {
  active = 0,
  pause = 1,
  finished = 2,
  all = 3,
}

export enum FrequentStatusType {
  finished = 0,
  active = 1,
  pause = 2,
  all = 3,
}

export enum NameColumn {
  fkCategory = 'fk_category',
  fkAccount = 'fk_account',
  fkSubcategory = 'fk_subcategory',
}

export enum TypeUpdate {
  all = 0,
  one = 1,
  next = 2
}

export type DateGroupBy = 'day' | 'month' | 'year' | 'week';
