import {Component, Input, OnInit, Output, EventEmitter, AfterViewInit, OnChanges} from '@angular/core';
import { LocalStorageManager } from '../../../utils/local-storage-manager/local-storage-manager.utils';
import { TranslateService } from '@ngx-translate/core';
import { AccountModel } from 'src/app/models/general/account.model';

@Component({
  selector: 'app-selector-multi-account',
  templateUrl: './selector-multi-account.component.html',
  styleUrls: ['./selector-multi-account.component.css']
})
export class SelectorMultiAccountComponent implements OnInit, OnChanges {

  @Input() accounts : AccountModel[] = [];
  @Input() showSelectAll : boolean = false;
  @Output() emmitAccountsSelected = new EventEmitter<AccountModel[]>();

  ls = LocalStorageManager;
  selectedAccountsPks : number[] = this.ls.movements.getAccountsIds();
  selectAll : boolean = false;
  isDarkMode = LocalStorageManager.isDarkmode;
  textColor: string = "";
  backgroundColor: string = ""
  accountNames: string = ""

  constructor(
    private translate: TranslateService
    ) {
    this.translate.use(this.ls.lang!);
    this.selectedAccountsPks = this.ls.movements.getAccountsIds();
  }


  ngOnChanges(): void {
    this.accounts.sort((a, b) => {
      return a.accountName.localeCompare(b.accountName);
    });
    this.setColors();
    this.setInitialSelectedAccounts();
    this.setAccountNames();
  }

  ngOnInit(): void {
    this.accounts.sort((a, b) => {
      return a.accountName.localeCompare(b.accountName);
    });
  }

  //MARK: PUBLIC METHODS -----------------------------------------------------------------------------
  public setAccount(account: AccountModel) {
    account.selected = (account.selected == 1) ? 0 : 1;
    this.selectedAccountsPks = this.accounts.filter(row => row.selected == 1).map(row => row.pkAccount);
    this.selectAll = (this.selectedAccountsPks.length === this.accounts.length);
    if (this.selectedAccountsPks.length == 0) {
      this.selectAllAccounts();
    }
    this.setAccountNames();
    this.saveAndEmitAccounts();
  }

  public selectAllAccounts() {
    this.selectAll =! this.selectAll;
    if (!this.selectAll) { return }
    this.accounts.forEach(account => account.selected = 1);
    this.selectedAccountsPks = this.accounts.map(row => row.pkAccount);
    this.saveAndEmitAccounts();
  }

  //MARK: PRIVATE METHODS ----------------------------------------------------------------------------
  private setColors(){
    this.textColor = this.isDarkMode ? "#FFF" : "#000";
    this.backgroundColor = this.isDarkMode? "#343A40" : "#FFFFFF";
  }

  private saveAndEmitAccounts() {
    this.ls.movements.setAccountsIds(this.selectedAccountsPks);
    this.emmitAccountsSelected.emit(this.accounts.filter(row => row.selected == 1));
  }

  private setAccountNames() {
    this.accountNames = "";
    this.accounts.forEach(row => {
      if (row.selected == 1)  {
        this.accountNames = `${this.accountNames}, ${row.accountName}`;
      }
    });
    if (this.accountNames.startsWith(',')) {
      this.accountNames = this.accountNames.substring(2);
    }
  }

  private setInitialSelectedAccounts() {
    const accountSelected = this.accounts.filter(row => this.selectedAccountsPks.includes(row.pkAccount));
    this.selectAll = (accountSelected.length === this.accounts.length || accountSelected.length === 0);
    if (this.selectAll) {
      this.accounts.forEach(row => row.selected = 1);
      this.emmitAccountsSelected.emit(this.accounts);
    } else {
      this.accounts.forEach(row => {
        row.selected = this.selectedAccountsPks.includes(row.pkAccount) ? 1 : 0;
      });
      this.emmitAccountsSelected.emit(accountSelected);
    }
  }
}
