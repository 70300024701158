import {Injectable} from '@angular/core';
import {ResponseApi3} from 'src/app/interfaces/responses-general.interface';
import {SyncDebt} from 'src/app/interfaces/sync.interface';
import {DebtModel} from 'src/app/models/general/debt.model';
import {DebtRecordModel} from 'src/app/models/general/debt.record.model';
import {Debt} from 'src/app/interfaces/general/debt.interface';
import {DebtRecord} from 'src/app/interfaces/general/debt.record.interface';
import {AccountModel} from 'src/app/models/general/account.model';
import {Account} from 'src/app/interfaces/general/account.interface';
import {STATUS_LIST} from 'src/app/interfaces/constants/status-list.constant';
import {Api3Service} from '../../../services/api-3.service';

@Injectable({
  providedIn: 'root'
})
export class DebtsService {

  private statusFilter = STATUS_LIST;
  private accountsModel: AccountModel[] = [];
  private debtsModel: DebtModel[] = [];
  private debtRecordsModel: DebtRecordModel[] = [];

  private subscriptionId = this.api3.userData?.subscription_id;
  private userId = this.api3.userData?.user_id;
  private tableName = 'table_debts';

  constructor(
    private api3: Api3Service
  ) {
  }

  //MARK: PUBLIC METHODS ----------------------------------------------------------------
  public getAccounts(): AccountModel[] {
    return [...this.accountsModel];
  }

  public getDebts(): DebtModel[] {
    return [...this.debtsModel];
  }

  public getSync() {
    return new Promise((resolve, reject) => {
      this.api3.sync('debts').subscribe((response: ResponseApi3<SyncDebt>) => {
        if (response.status === 1) {
          this.setDebtRecordsModel(response.data.table_debt_records);
          this.setAccountModel(response.data.table_accounts);
          this.setDebtModel(response.data.table_debts);
          resolve(true);
        } else {
          reject(response.message);
        }
      });
    });
  }

  public createDebt(value: Debt) {
    value.fk_user = this.userId!;
    const params = {data: value};
    return new Promise((resolve, reject) => {
      this.api3.insert(params, this.tableName).subscribe((response: ResponseApi3<Debt[]>) => {
        if (response.status === 1) {
          const model = new DebtModel(response.data[0]);
          this.setRelationsDebt(model);
          this.debtsModel.push(model);
          resolve(true);
        } else {
          reject(response.message);
        }
      });
    });
  }

  public updateDebt(values: Debt, debt: DebtModel) {
    values.fk_user = this.userId!;
    const params = {data: values};
    return new Promise((resolve, reject) => {
      this.api3.update(params, this.tableName).subscribe((response: ResponseApi3<Debt[]>) => {
        if (response.status == 1) {
          const data = response.data[0];
          debt.updateValues(data);
          debt.debtRecords = [];
          this.setRelationsDebt(debt);
          resolve(true);
        } else {
          reject(response.message);
        }
      });
    });
  }

  public deleteDebt(debt: Debt) {
    const params = {
      pk_debt: debt.pk_debt,
      fk_subscription: this.subscriptionId,
      fk_user: this.userId
    };
    return new Promise((resolve, reject) => {
      this.api3.delete(params, this.tableName).subscribe((response: ResponseApi3<any>) => {
        if (response.status == 1) {
          this.debtsModel = this.debtsModel.filter(({pkDebt}) => pkDebt != debt.pk_debt);
          resolve(true);
        } else {
          reject(response.message);
        }
      });
    });
  }

  public setTotalDebt(model: DebtModel) {
    let total = 0;
    model.debtRecords.map((row) => {
      if (!row.isEditable) {
        return;
      }
      if (row.sign == '-') {
        total = total - row.amount;
      } else {
        total = total + row.amount;
      }
    });
    model.setPercentage(total);
  }

  //MARK: PRIVATE METHODS ---------------------------------------------------------------
  private setAccountModel(accounts: Account[]) {
    this.accountsModel = [];
    for (const account of accounts) {
      this.accountsModel.push(new AccountModel(account));
    }
  }

  private setDebtModel(debts: Debt[]) {
    this.debtsModel = [];
    for (const debt of debts) {
      const model = new DebtModel(debt);
      this.setRelationsDebt(model);
      this.debtsModel.push(model);
    }
  }

  private setRelationsDebt(model: DebtModel) {
    const account = this.accountsModel.find(({pkAccount}) => pkAccount == model.fkAccount);
    model.debtRecords = this.debtRecordsModel.filter(({fkDebt}) => fkDebt == model.pkDebt);
    model.statusData = this.statusFilter[model.status];
    if (account) {
      model.account = account;
    }
    this.setTotalDebt(model);
  }

  private setDebtRecordsModel(debtRecords: DebtRecord[]) {
    this.debtRecordsModel = [];
    for (const record of debtRecords) {
      this.debtRecordsModel.push(new DebtRecordModel(record));
    }
  }
}
