import { Injectable } from '@angular/core';
import { PreferencesModel } from '../../../models/preferences.model';
import {Preference} from '../../../interfaces/general/preference.interface';
import {ResponseApi3} from 'src/app/interfaces/responses-general.interface';
import {PreferencesSessionStorage} from '../../../utils/local-storage-manager/session-storage-preferences.utils';
import {Api3Service} from '../../../services/api-3.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private api3: Api3Service
  ) { }

  public updatePreferences(values: any) {
    const params = {
      data: {
        ...values,
        transfers: values.transfers ? 1 : 0
      }
    }
    return new Promise((resolve, reject) => {
      this.api3.update(params, 'user_preferences').subscribe((response: ResponseApi3<Preference[]>)=> {
        if (response.status === 1) {
          this.updateModel(response.data[0])
          resolve(true);
        } else {
          reject(response.message);
        }
      });
    });
  }

  private updateModel(data: Preference) {
    PreferencesModel.shared.dateFormat = data.format_date;
    PreferencesModel.shared.decimalFormat = data.decimal_format;
    PreferencesModel.shared.decimalNumber = data.decimal_number;
    PreferencesModel.shared.firstWeekDay = data.first_weekday;
    PreferencesModel.shared.fkCurrency = data.fk_currency;
    PreferencesModel.shared.fkUser = data.fk_user;
    PreferencesModel.shared.floatingButton = data.floating_button;
    PreferencesModel.shared.pkPreference = data.pk_preference;
    PreferencesModel.shared.showIsoCode = data.show_iso_code;
    PreferencesModel.shared.showSymbol = data.show_symbol;
    PreferencesModel.shared.symbolSide = data.symbol_side;
    PreferencesModel.shared.theme = data.theme;
    PreferencesModel.shared.timeFormat = data.format_time;
    PreferencesModel.shared.transfers = data.transfers == 1;
    this.updateLocalStorage(data);
  }

  private updateLocalStorage(preferences: Preference) {
    const storage = new PreferencesSessionStorage();
    storage.savePreferences(preferences);
  }
}
