<div class="dropdown">
  <button
    [ngStyle]="{
       'background-color': backgroundColor,
       'color': textColor
    }"
    class="btn col-12 btn-selector dropdown-toggle truncate-button ps-1 pe-1"
    type="button"
    data-bs-toggle="dropdown"
    aria-expanded="false">
      {{ selectAll ? ('selecteds.all_accounts' | translate) : accountNames }}
  </button>
  <div
    [ngStyle]="{
       'background-color': backgroundColor,
       'color': textColor
    }"
    class="dropdown-menu size-menu col-12 mt-1 pt-1 p-2">
    <!-- ALL ACCOUNTS -->
    <div
      (click)="!selectAll ? selectAllAccounts() : null"
      class="btn col-12 item-account mt-1 text-start p-1 ps-3"
      [ngStyle]="{
       'background-color': selectAll ? '#000' : backgroundColor,
       'color': selectAll ? '#FFF' : textColor
      }">
      <img
        class="img-icon me-2"
        [ngClass]="{
          'filter-white': selectAll || isDarkMode,
          'filter-black-1': !selectAll && !isDarkMode
        }"
        src="./assets/images/icon-cat/icon_all_accounts.svg"
        alt="">
      {{ "selecteds.all_accounts" | translate }}
    </div>
    <div *ngFor="let account of accounts">
      <!-- ROW ACCOUNT -->
      <div
        class="btn col-12 item-account mt-1 text-start p-0 ps-3"
        (click)="setAccount(account); $event.stopPropagation()"
        [ngStyle]="{
          'background-color': (account.selected == 1) ? '#'+account.colorHex : backgroundColor,
          'color': (account.selected == 1) ? '#FFF' : textColor
        }">
        <!-- ICON ACCOUNT -->
        <img
          class="img-icon me-2"
          [ngClass]="{ 'filter-white': account.selected == 1}"
          style="filter: {{ account.selected == 1 ? '' : account.filter }}"
          src="./assets/images/icon-cat/{{account.iconName}}.svg"
          alt="">
        <!-- ACCOUNT NAME -->
        {{ account.accountName }}
        <!-- ICON SHARED -->
        <i
          *ngIf="account.isShared"
          class="fa fa-user-friends align-self-center col-1 pe-0 gray"
          aria-hidden="true"
        ></i>
        <!-- ICON DONE -->
        <img
          *ngIf="account.selected == 1"
          src="./assets/images/icon-done.png"
          alt=""
          width="25"
          height="25"
          class="icon-done">
      </div>
    </div>
  </div>
</div>
