<app-maintenance
    *ngIf="maintenance"
    >
</app-maintenance>

<ng-container *ngIf="!loading && !maintenance">
    <nav
        class="navbar navbar-light bg-light sticky-top"
        [ngClass]="{'navbar-dark bg-dark white-text': ls.isDarkmode}"
        >
        <div class="col-6">
            <div class="row">
                <div class="ms-3 col-1">
                    <a
                        class="navbar-brand"
                        routerLink = "Home"
                        (click)="changePage('Home')"
                        >
                        <img
                            src="assets/images/GD4-120.png"
                            width="40"
                            height="40"
                            class="d-inline-block align-top ms-1 me-1"
                            loading="lazy"
                            >
                    </a>
                </div>
                <div class="col-6 marginTitlePage">
                    <h2
                        *ngIf="page"
                        > {{ page | translate }}
                    </h2>
                    <h2
                        *ngIf="!page"
                        > {{'main_menu.menu_home' | translate}}
                    </h2>
                </div>
            </div>
        </div>


        <div class="col-12 col-md-2">
            <div class="row btn-profile" >
                <div class="dropdown-center offset-3 col-6">
                    <div
                        class="text-end ms-5 mt-1"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false">
                            <h6
                            *ngIf="user != null"
                            class="no-space"
                            >{{user.name}}
                            </h6>
                            <p class="gray tiny no-space">
                                {{ (type_subscription == 1200 ? "subscription_section.plan_for_life" : (type_subscription == 1 ? "subscription_section.plan_monthly" : "subscription_section.plan_yearly")) | translate }}
                            </p>
                    </div>
                    <ul
                        class="dropdown-menu"
                        [ngClass]="{'bg-dark': ls.isDarkmode}">
                        <li>
                            <a
                                class="dropdown-item no-decorate-text"
                                [ngClass]="{'text-white': ls.isDarkmode}"
                                (click)="changePage('Perfil')"
                                routerLink="Profile"
                                >{{'settions_section.settings_profile_user' | translate}}
                                </a>
                        </li>
                        <li>
                            <a
                                class="dropdown-item no-decorate-text"
                                [ngClass]="{'text-white': ls.isDarkmode}"
                                (click)="logout()"
                                >{{ 'profile_section.button_logout' | translate }}
                                </a>
                        </li>
                    </ul>
                </div>
                <div class="user-background col-6 no-padding"
                    [ngClass]="{'f-center' : !hasPhotoNameUser()}">
                    <img
                        id="dropdownMenuButton"
                        [src]="hasPhotoNameUser() ? urlImage : 'assets/images/icon-user.png'"
                        class="rounded-circle"
                        [ngClass]="{
                            'filter-black-6' : !ls.isDarkmode && !hasPhotoNameUser(),
                            'f-center h-75 w-75' : !hasPhotoNameUser(),
                            'rounded-circle h-100 w-100' : hasPhotoNameUser()
                        }">
                </div>
            </div>
        </div>
    </nav>

    <!-- COLLAPSED SIDE BAR MENU -->
    <body
        class="text-center bg-light"
        [ngClass]="{'bg-dark': ls.isDarkmode}"
        style="isolation: isolate;"
        >

        <div
            class="container button-no-visible darkmode-ignore"
            [ngClass]="{'bg-dark': ls.isDarkmode}"
            >
            <div class="row text-end text-right">
                <div class="col">
                    <button
                        class="btn btn-light m-2 darkmode-ignore"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseMenu"
                        aria-expanded="true"
                        aria-controls="collapseMenu"
                        [ngClass]="{'btn-dark': ls.isDarkmode}"
                        >
                        <i class="fa fa-bars" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </div>
    </body>

    <div
        class="sidebar"
        [ngClass]="{'bg-dark white-text': ls.isDarkmode}"
        >
        <div
            class="collapse d-md-none d-lg-none d-xl-none"
            id="collapseMenu"
            >
            <ng-container *ngFor="let section of menu.data">
                <ng-container *ngFor="let option of section.options">
                    <a
                        *ngIf="option.title != 'Modal-about'"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseMenu"
                        (click)="changePage(option.title)"
                        [routerLink]="'/'+option.link"
                        routerLinkActive="active"
                        >
                        <img
                            src="assets/images/{{option.iconName}}.svg"
                            width="20px"
                            > {{ option.title | translate }}
                    </a>
                </ng-container>
                <hr class="no-visible">
            </ng-container>
      </div>
    </div>

    <!-- SIDE BAR MENU -->
    <div
        class="sidebar d-none d-sm-none d-md-block d-lg-block d-xl-block overflow-auto"
        [ngClass]="{'bg-dark white-text': ls.isDarkmode}"
        >
        <ng-container *ngFor="let section of menu.data">
            <ng-container *ngFor="let option of section.options">
                <a
                    *ngIf="option.isRoute"
                    (click)="changePage(option.title)"
                    [routerLink]="option.link"
                    routerLinkActive="active"
                    >
                    <img
                        routerLinkActive="filter-black-1"
                        [ngClass]="{'filter-white' : ls.isDarkmode, 'filter-black-1': !ls.isDarkmode}"
                        src="assets/images/{{option.iconName}}.svg"
                        width="20px"
                        > {{ option.title  | translate }}
                </a>
                <a
                    *ngIf="!option.isRoute"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-about-gd4"
                    href="#"
                    routerLinkActive="false"
                    >
                    <img
                        routerLinkActive="filter-black-1"
                        [ngClass]="{'filter-white' : ls.isDarkmode, 'filter-black-1': !ls.isDarkmode}"
                        src="assets/images/{{option.iconName}}.svg"
                        width="20px"
                        > {{ option.title  | translate }}
                </a>
            </ng-container>
            <hr class="no-visible">
        </ng-container>
    </div>
    <!-- Page content -->
    <div
        class="bg-primary-light"
        [ngClass]="{'bg-primary-dark': ls.isDarkmode}"
        >
        <app-message-download
            (emmiter)="setShow($event)"
            >
        </app-message-download>
        <div class="content">
            <router-outlet></router-outlet>
        </div>
    </div>
</ng-container>

<app-modal-about></app-modal-about>
