import { Component, OnInit } from '@angular/core';
import { LocalStorageManager } from '../../../utils/local-storage-manager/local-storage-manager.utils';
import {NU_VERSION} from '../../../interfaces/constants/status-list.constant';

@Component({
  selector: 'app-modal-about',
  templateUrl: './modal-about.component.html',
  styleUrls: ['./modal-about.component.css']
})
export class ModalAboutComponent implements OnInit {

  ls = LocalStorageManager;
  type_subscription: number = 0;
  name_subscription: string = 'plan_monthly';

  constructor() {
    this.type_subscription = +sessionStorage.getItem('type_subscription')!;
    switch (this.type_subscription) {
      case 1:
        this.name_subscription = 'plan_monthly';
        break;
      case 2:
        this.name_subscription = 'plan_yearly';
        break;
      default:
        this.name_subscription = 'plan_for_life';
        break;
    }
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void { }

  protected readonly NU_VERSION = NU_VERSION;
}
