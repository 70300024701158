X<div
    *ngIf="!loading"
    class="animate__animated animate__fadeIn"
    >
    <div class="container mt-2 mb-2">
        <div class="row mt-4">
            <!-- COMPONENT ACCOUNTS -->
            <div class="col-4">
                <!-- col-8 col-lg-10 col-xl-2 -->
                <app-selector-multi-account
                    *ngIf="!isDragEnabled"
                    showSelectAll="true"
                    [accounts]="accounts"
                    (emmitAccountsSelected)="setSelectedAccounts($event)"
                    >
                </app-selector-multi-account>
                <h6
                    *ngIf="isDragEnabled"
                    class="pointer"
                    [ngClass]="{'white-text': isDarkmode}"
                    (click)="getData()"
                    >{{ "buttons.button_cancel" | translate}}
                </h6>
            </div>
            <!-- COMPONENT PERIOD -->
            <div class="offset-1 offset-lg-0 offset-xl-0 col-5 col-lg-6 col-xl-2">
                <app-selector-period
                    *ngIf="!isDragEnabled"
                    [selectedPeriod]="selectedPeriod.period"
                    (emitter)="changePeriod($event)"
                    >
                </app-selector-period>
            </div>
            <!-- COMPONENT DATE -->
            <div
                class="col-12 col-xl-4"
                >
                <app-selector-date-periods
                  [period]="selectedPeriod.period"
                  [currentDate]="date"
                  [requiredAfterPeriod]="true"
                  (date)="setDate($event)"
                  (rangeDates)="changeRangeDate($event)"
                  (afterRangeDates)="setAfterRangeDates($event)"
                  >
                </app-selector-date-periods>
            </div>
            <!-- COMPONENT EDIT VIEWS -->
            <div class="col-12 col-lg-6 col-xl-2 pointer">
                <h6
                    (click)="isDragEnabled ? updateCards() : toggleDragDropMode()"
                    style="font-size: 18px; border-radius: 10px;"
                    class="no-margin p-2 text-center"
                    [ngClass]="{'white-text': isDarkmode, 'bg-dark-input' : isDarkmode, 'bg-white' : !isDarkmode}"
                    >
                    {{ (isDragEnabled ? 'buttons.button_save' : 'title_cardviews.card_edit_views') | translate}}
                </h6>
            </div>
        </div>
    </div>
    <div class="container scrollable mt-4 mb-4">
        <ul
            *ngIf="!loading"
            id="list"
            class="uk-grid-small uk-child-width-1-2 uk-child-width-1-3@s"
            uk-sortable="handle: .uk-sortable-handle"
            uk-grid="masonry: true">
            <ng-container
                *ngFor="let userCard of userCards"
                >
                <li
                    class="col-12 col-sm-6 col-lg-4"
                    *ngIf="userCard.card.type != cardsTypes.buttons"
                    [value]="userCard.card.id">
                  <!--&& (!userCard.card.hidden || isDragEnabled)-->
                    <div class="uk-card">
                        <div
                            *ngIf="isDragEnabled"
                            class="uk-sortable-handle over-right animate__animated animate__fadeIn col-12"
                            >
                            <div class="row me-2">
                                <label class="switch mt-2 me-4">
                                    <input
                                        type="checkbox"
                                        [checked]="!userCard.card.hidden"
                                        (change)="toggleCardHidden(userCard.card)"
                                        >
                                    <span class="slider round"></span>
                                </label>
                                <i
                                    class="fas fa-align-justify fa-2x"
                                    [ngClass]="{'filter-white': isDarkmode}"
                                    >
                                </i>
                            </div>
                        </div>
                        <div
                          [ngClass]="{'card-disabled': isDragEnabled}"
                          *ngIf="showCards">
                            <div *ngIf="!userCard.card.hidden">
                              <ng-container
                                  *ngComponentOutlet="userCard.card.component"
                                >
                              </ng-container>
                            </div>
                            <div
                                *ngIf="userCard.card.hidden && isDragEnabled"
                                style="height: 10vh; width: 5vw">
                            </div>
                        </div>
                    </div>
                </li>
            </ng-container>
        </ul>
    </div>
   <app-not-have-data
        *ngIf="notHaveData"
        [message]="message"
        >
   </app-not-have-data>
</div>

<app-loading-view
  *ngIf="loading">
</app-loading-view>

<app-loading-change-view
  [loading]="loadingChange">
</app-loading-change-view>

<app-message-info-alert
  [keyMessage]="keyMessage">
</app-message-info-alert>
