import { Injectable } from '@angular/core';
import {Api3Service} from './api-3.service';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceServicesService {

  constructor(
    private api3: Api3Service
  ) { }

  public getMaintenance() {
    return new Promise((resolve, reject) => {
      this.api3.services().subscribe(response => {
        if (response.status === 1) {
          reject(response.message);
        } else {
          resolve(true)
        }
      });
    });
  }
}
