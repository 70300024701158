<div
    class="modal fade"
    id="modal-about-gd4"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    >
    <div
        class="modal-dialog modal modal-dialog-centered"
        role="document"
        >
        <div
            class="modal-content rounded"
            [ngClass]="{'bg-dark': ls.isDarkmode}"
            >
            <div
                class="modal-header"
                >
                <h4
                    ngClass="{{ls.isDarkmode ? 'text-body-color-discreet' : 'text-title-color-discreet'}}"
                    class="modal-title text-center"
                    >{{ "settions_section.settings_about" | translate }}
                </h4>
                <img
                    #buttonCloseModalWarning
                    src="assets/images/icon-close.svg"
                    height="20px"
                    width="20px"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    >
            </div>
        <div
            class="modal-body text-center center no-margin no-padding"
            [ngClass]="{'white-text': ls.isDarkmode}"
            >
            <div class="container">
                <div class="row">
                    <div
                        class="col-12 no-margin no-padding background-image-gd4-purple"
                        style="background-image: url(assets/images/about_web.png);"
                        >
                        <div
                            class="purple-gd4 pb-2"
                            style="margin-top: 53%;"
                            >
                            <h3 [ngClass]="{'white-text' : ls.isDarkmode}">{{"app_name" | translate}}</h3>
                            <h5 [ngClass]="{'white-text' : ls.isDarkmode}">{{NU_VERSION}}</h5>
                            <h5 [ngClass]="{'white-text' : ls.isDarkmode}">{{'subscription_section.'+name_subscription | translate}}</h5>
                        </div>
                    </div>
                </div>
                    <div class="mt-2">
                        <p
                            class="no-margin no-padding"
                            ngClass="{{ls.isDarkmode ? 'text-body-color-discreet' : 'text-title-color-discreet'}}"
                            >{{"about_section.about_email_support" | translate}}</p>
                        <p
                            class="no-margin no-padding"
                            ngClass="{{ls.isDarkmode ? 'text-body-color-discreet' : 'text-title-color-discreet'}}"
                            >support@encodemx.com</p>
                    </div>
                    <div class="mt-4">
                        <h3 ngClass="{{ls.isDarkmode ? 'text-body-color-discreet' : 'text-title-color-discreet'}}">{{"about_section.about_team" | translate}}</h3>
                        <div class="mt-2">
                            <div>
                                <h5 [ngClass]="{'white-text' : ls.isDarkmode}">Michel Carvajal</h5>
                                <p
                                    ngClass="{{ls.isDarkmode ? 'text-body-color-discreet' : 'text-title-color-discreet'}}"
                                    >{{"about_section.about_text_1" | translate}}</p>
                            </div>
                            <div class="mt-2">
                                <h5 [ngClass]="{'white-text' : ls.isDarkmode}">Samuel Guerrero</h5>
                                <p
                                    ngClass="{{ls.isDarkmode ? 'text-body-color-discreet' : 'text-title-color-discreet'}}"
                                    >{{"about_section.about_text_2" | translate}}</p>
                            </div>
                            <div class="mt-2">
                                <h5 [ngClass]="{'white-text' : ls.isDarkmode}">Rafael Ortega</h5>
                                <p
                                    ngClass="{{ls.isDarkmode ? 'text-body-color-discreet' : 'text-title-color-discreet'}}"
                                    >{{"about_section.about_text_3" | translate}}</p>
                            </div>
                            <div class="mt-2">
                                <h5 [ngClass]="{'white-text' : ls.isDarkmode}">Oscar Martínez</h5>
                                <p
                                    ngClass="{{ls.isDarkmode ? 'text-body-color-discreet' : 'text-title-color-discreet'}}"
                                    >{{"about_section.about_text_4" | translate}}</p>
                            </div>
                            <div class="mt-2">
                              <h5 [ngClass]="{'white-text' : ls.isDarkmode}">Aksel Herrera</h5>
                              <p
                                ngClass="{{ls.isDarkmode ? 'text-body-color-discreet' : 'text-title-color-discreet'}}"
                              >{{"about_section.about_text_3" | translate}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
