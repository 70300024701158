import { Injectable } from '@angular/core';
import { Currency } from '../shared/navbar/interfaces/userNavbar.interface';
import { PreferencesModel } from '../models/preferences.model';
import { UserPreference } from './interfaces/syncAll.interface';
import { ModelCurrency } from '../models/currency.model';
import {Preference} from '../interfaces/general/preference.interface';
import {Api3Service} from './api-3.service';
import {ResponseApi3} from '../interfaces/responses-general.interface';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private _preference : PreferencesModel[] = [];
  private _currencies : ModelCurrency[] = [];

  public get preferences() : PreferencesModel[] {
    return [...this._preference];
  }

  public get currencies() : ModelCurrency[] {
    return [...this._currencies];
  }

  constructor(
    private api3: Api3Service
  ) { }

  //MARK: PUBLIC METHODS -----------------------------------------------------------------------------
  public getCurrentUser() {
    return new Promise((resolve, reject) => {
      this.api3.sync('user').subscribe((response: ResponseApi3<any>) => {
        if (response.status === 1) {
          let user = response.data.user[0];
          this._preference = this.convertPreference(response.data.preferences);
          this._currencies = this.convertCurrency(response.data.currencies);
          resolve(user);
        } else {
          reject(response.status);
        }
      });
    });
  }

  public getUrlImage(imgName: string): Promise<string> {
    const params = {
      data: {
        name: imgName,
        email: this.api3.userData?.email,
        profile: 1
      }
    }
    return new Promise((resolve, reject) => {
      this.api3.post(params, 'aws_file', 'public_url').subscribe((response: ResponseApi3<any>) => {
        if (response.status === 1) {
          resolve(response.data);
        } else {
          reject();
        }
      });
    });
  }

  //MARK: PRIVATE METHODS ----------------------------------------------------------------------------
  convertPreference(preferences : UserPreference[]) {
    let models : PreferencesModel[] = [];
    if (preferences == undefined) {
      return [];
    }
    preferences.forEach(preference => {
      const model = this.getModel(preference as any);
      models.push(model);
    });
    return models;
  }

  convertCurrency(currencies : Currency[]) {
    let models : ModelCurrency[] = [];
    if (currencies == undefined) {
      return [];
    }
    currencies.forEach(currency => {
        const model = this.getModelCurrency(currency);
        models.push(model);
    });
    return models;
  }

  getModelCurrency(currency : Currency) {
    return new ModelCurrency(+currency.pk_currency, currency.iso_code, currency.symbol);
  }

  getModel(record : Preference) {
    return new PreferencesModel(record);
  }
}
