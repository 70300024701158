import { Injectable } from '@angular/core';
import {SessionData} from '../shared/login/interfaces/session.interfaces';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class Api3Service {

  public url = "https://backend.dailyexpenses4.com/api_3.0/request.php";
  public userData?: SessionData = JSON.parse(sessionStorage.getItem('user_data')!);
  public dbServer = "MAIN";
  private headers = new HttpHeaders({ 'x-token' : this.userData?.token ?? '' });
  private requestOptions = { headers: this.headers };

  constructor(private http: HttpClient) { }

  //MARK: PUBLIC METHODS -----------------------------------------------------------------------------
  public sync(option: string) {
    const data = { pk_user : this.userData?.user_id };
    const paramsData = this.getParamsData(data, 'sync_web', option);
    return this.http.post(this.url, paramsData, this.requestOptions) as Observable<any>;
  }

  public post(data: object, request: string, option: string) {
    const paramsData = this.getParamsData(data, request, option);
    return this.http.post(this.url, paramsData, this.requestOptions) as Observable<any>;
  }

  public withoutToken(data: object, request: string, option: string) {
    const params = this.getParamsNotAuth(data, request, option);
    const headers = { headers: this.defaultHeaders() }
    return this.http.post(this.url, params, headers) as Observable<any>;
  }

  public insert(data: object, tableName: string) {
    const paramsData = this.getParamsData(data, 'insert', tableName);
    return this.http.post(this.url, paramsData, this.requestOptions) as Observable<any>;
  }

  public update(data: object, tableName: string) {
    const paramsData = this.getParamsData(data, 'update', tableName);
    return this.http.post(this.url, paramsData, this.requestOptions) as Observable<any>;
  }

  public delete(data: object, tableName: string) {
    const dataObjc = { data: data }
    const paramsData = this.getParamsData(dataObjc, 'delete', tableName);
    return this.http.post(this.url, paramsData, this.requestOptions) as Observable<any>;
  }

  public file(params: any, ) {
    const headers = { headers: this.getHeaderFile() };
    return this.http.post(this.url, params, headers) as Observable<any>;
  }

  public services() {
    return this.http.post('https://backend.dailyexpenses4.com/api_3.0/services.php', null, this.requestOptions) as Observable<any>;
  }

  //MARK: PRIVATE METHODS ----------------------------------------------------------------------------
  private getParamsData(data: object, type: string, tableName: string) {
    return {
      request: type,
      database: this.dbServer,
      option: tableName,
      ...data,
      auth_params: {
        user_id: this.userData?.user_id.toString(),
        email: this.userData?.email
      }
    }
  }

  private getParamsNotAuth(data: object, type: string, option: string) {
    return {
      request: type,
      database: this.dbServer,
      option: option,
     ...data
    }
  }

  private defaultHeaders() {
    return new HttpHeaders({
      'x-token': '0CRdlVnmoI9gHGNd68JHT0lM6qVLaEgnzq3wqxvD87Fur8sXx0Z80riMl2dWySwJB2UqlENEZKMW37Y0eeir9aMG0a1UqoO7NptclWtSi32soObIlTygi5UG06rroGlnig82yPexjNuaADHCcIsYQP5lxOvIQO2089rmAh2cIJ609V0AZz0WdjQkzmXlgV1nU2N06WCz',
    })
  }

  private getHeaderFile() {
    return new HttpHeaders({
      'x-token': this.userData!.token,
      'email': this.userData!.email,
      'user-id': this.userData!.user_id.toString()
    })
  }
}
