import { Injectable } from '@angular/core';
import currencies from '../../json/GD4-currencies.json'
import { ModelCurrency } from '../../models/currency.model';
import {Api3Service} from '../../services/api-3.service';
import {ResponseApi3} from '../../interfaces/responses-general.interface';

@Injectable({
  providedIn: 'root'
})
export class CurrenciesService {

  private _currencies:ModelCurrency[] = [];

  public get currencies():ModelCurrency[] {
    return [...this._currencies];
  }

  constructor(
    private api3: Api3Service
  ) { }

  getCurrencies(currenciesUser : ModelCurrency[]) {
    this._currencies = [];
    currencies.forEach(currency => {
      this._currencies.push(new ModelCurrency(+currency.pk_currency, currency.iso_code, currency.symbol));
    });

    if (currenciesUser) {
      currenciesUser.forEach(currency => {
        this._currencies.push(new ModelCurrency(+currency.pkCurrency, currency.isoCode, currency.symbol));
      })
    }
  }

  public addCurrency(values : any) {
    const params = {
      data: {
        ...values,
        fk_user: this.api3.userData?.user_id
      }
    }
    return new Promise((resolve, reject) => {
      this.api3.insert(params, 'table_currencies').subscribe((response: ResponseApi3<any>) => {
        if (response.status === 1) {
          let currency = response.data[0];
          this._currencies.push(new ModelCurrency(+currency.pk_currency, currency.iso_code, currency.symbol));
          resolve(true);
        } else {
          reject(response.message);
        }
      });
    });
  }

}
